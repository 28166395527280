/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui"

import SectionHeading from "components/SectionHeading"
import Accordion from "components/Accordion"

interface QASectionProps {
  //enables sx prop to be passed down from parent
  accordions: { id: number; question: string; answer: string }[]
  className?: string
  title: string
}

const QASection = ({ className, accordions, title }: QASectionProps) => {
  return (
    <Box className={className} as="section" sx={{ mb: [12, null, 28] }}>
      <SectionHeading indented>{title}</SectionHeading>

      <Container variant="layout.narrow">
        {accordions.map((data, index) => (
          <Accordion
            key={data.id}
            title={data.question}
            content={data.answer}
          />
        ))}
      </Container>
    </Box>
  )
}

export default QASection
