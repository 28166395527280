/** @jsx jsx */
import { jsx, Flex, Box, Heading } from "theme-ui"
import { useState, createRef } from "react"

import PlusIcon from "images/icons/aguila-plusicon.svg"

import MdContent from "components/common/MdContent"

interface AccordionProps {
  //enables sx prop to be passed down from parent
  className?: string
  title: string
  content: string
}

const Accordion = ({ className, title, content }: AccordionProps) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setOpacity, setOpacityState] = useState(1)

  const contentContainer = createRef<HTMLDivElement>()

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active"
        ? "0px"
        : `${contentContainer?.current?.scrollHeight}px`
    )
    setOpacityState(setActive === "active" ? 1 : 0)
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        py: 4,
      }}
    >
      <button
        type="button"
        sx={{
          backgroundColor: "transparent",
          textAlign: "left",
          border: 0,
          px: 0,
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "primary",
          outline: "none",
        }}
        className={`accordion ${setActive}`}
        onClick={toggleAccordion}
      >
        <Heading as="h2" variant="title" sx={{ color: "text" }}>
          {title}
        </Heading>
        <PlusIcon
          sx={{
            width: ["20px", null, "40px"],
            height: ["20px", null, "40px"],
            ml: 0,
            "& rect:nth-of-type(2)": {
              opacity: `${setOpacity}`,
              transition: "opacity 0.3s ease",
            },
          }}
        />
      </button>
      <Box
        ref={contentContainer}
        sx={{
          overflow: "hidden",
          transition: "max-height 0.6s ease",
          maxHeight: `${setHeight}`,
          contain: "layout",
        }}
      >
        <MdContent source={content} sx={{ py: 3 }} />
      </Box>
    </Flex>
  )
}

export default Accordion
