/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

import QASection from "components/QASection"

interface FAQBlockMapperProps {
  block: any
}

const FAQBlockMapper = ({ block }: FAQBlockMapperProps) => {
  switch (block.strapi_component) {
    case "sections.qa-section":
      const { title, qa } = block
      return <QASection accordions={qa} title={title} />
    default:
      return <></>
  }
}

export default FAQBlockMapper
