/** @jsx jsx */
import { graphql, PageProps } from "gatsby"
import { jsx } from "theme-ui"
import { IGatsbyImageData } from "gatsby-plugin-image"

import type { FaqPageQuery } from "../../graphql-types"

import FAQBlockMapper from "components/block-mappers/FAQBlockMapper"
import Layout from "components/Layout"
import PageHero from "components/PageHero"

interface FaqPageProps extends PageProps {
  data: FaqPageQuery
}

const FAQPage = ({ data, ...props }: FaqPageProps) => {
  return (
    <Layout seo={data.strapiFaq?.seo} location={props.location}>
      <PageHero
        title={data.strapiFaq?.Hero?.title as string}
        subtitle={data.strapiFaq?.Hero?.subtitle as string}
        content={data.strapiFaq?.Hero?.content as string}
        hasLines={data.strapiFaq?.Hero?.has_lines as boolean}
        hasLocatorCTA={data.strapiFaq?.Hero?.has_locator as boolean}
        imagePosition={data.strapiFaq?.Hero?.image_position as string}
        image={
          data.strapiFaq?.Hero?.image?.localFile?.childImageSharp
            ?.gatsbyImageData as IGatsbyImageData
        }
        imageAlt={data.strapiFaq?.Hero?.image?.alternativeText}
        buttonOneUrl={
          data.strapiFaq?.Hero?.hero_links?.[0]?.link_button_url as string
        }
        buttonOneText={
          data.strapiFaq?.Hero?.hero_links?.[0]?.link_button_text as string
        }
        buttonTwoUrl={
          data.strapiFaq?.Hero?.hero_links?.[1]?.link_button_url as string
        }
        buttonTwoText={
          data.strapiFaq?.Hero?.hero_links?.[1]?.link_button_text as string
        }
      />

      {data.strapiFaq?.blocks.map((block: any, index: number) => (
        <FAQBlockMapper key={index} block={block} />
      ))}
    </Layout>
  )
}

export default FAQPage

export const pageQuery = graphql`
  query FAQPage {
    strapiFaq {
      blocks
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
  }
`
